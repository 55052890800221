import React from 'react';
import { createRoot } from 'react-dom/client';

import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import './index.css';
import App from './components/App';

import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
createRoot(rootElement).render(
  <App />
);

reportWebVitals();
