import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'bootstrap';
import './App.css';
import { useForm } from '../hooks/useForm.js';
import SelectConeat from './selectConeat.jsx';

import DACTYLIS from './Bases/DACTYLIS';
import FESTUCA from './Bases/FESTUCA';
import LOTUSCORNICULATUS from './Bases/LOTUSCORNICULATUS';
import LOTUSULIGINOSUS from './Bases/LOTUSULIGINOSUS.jsx';
import ALFALFA from './Bases/ALFALFA';
import C4 from './Bases/C4';
import TREBOLROJO from './Bases/TREBOLROJO';
import RAIGRASPERENNE from './Bases/RAIGRASPERENNE';
import CEBADILLA from './Bases/CEBADILLA';
import HOLCUS from './Bases/HOLCUS';
let firstList = [];
function obtenerValorSeleccionado(nombreGrupoRadio) {
  var radios = document.getElementsByName(nombreGrupoRadio);
  for (var i = 0; i < radios.length; i++) {
      if (radios[i].checked) {
          return radios[i].value;
      }
  }
  return null;
}
function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSpecies, setSelectedSpecies] = useState(null);
  const modalRef = useRef(null);
  let phValue;
  let drenajeValue;

  const handleCtooltipClick = function(event, elemNombre) {
    if (event.target.classList.contains('ctooltip')) {
      event.preventDefault();
      event.stopPropagation();
      // Aquí puedes agregar más lógica para manejar el clic en ctooltip
    } else {
      // Abre el modal correspondiente
      openModal(elemNombre);
    }
  }
  
  const openModal= function(elemNombre) {
    const modal = new Modal(document.getElementById(elemNombre.replace(/\s+/g, '')));
    modal.show();
  }
  
  const arrayForModal = [
    {
      nombre: "FESTUCA",
      desc: "Festuca es una especie gramínea perenne de alta persistencia en las condiciones edafoclimáticas del Uruguay. Concentra su producción en las estaciones de otoño-invierno-primavera. Se destaca por sobre otras especies gramíneas con similares características como el Dactylis por una mayor tolerancia a drenajes imperfectos y una mayor producción de forraje.",
      img: "./imagenes-modal/Festuca.jpg"
    },
    {
      nombre: "DACTYLIS",
      desc: "Dactylis es una especie gramínea perenne que puede sobrevivir por 4-5 años si se realiza un manejo adecuado de remanentes en verano. Es una especie que concentra su producción en las estaciones de otoño-invierno-primavera. Se desarrolla bien sobre suelos bien drenados. Se destaca por sobre la Festuca por tener una mayor calidad del forraje producido y una mayor tolerancia al déficit hídrico.",
      img: "./imagenes-modal/Dactylis.jpg"
    },
    {
      nombre: "PASPALUM",
      desc: "Paspalum es una gramínea perenne estival nativa del Uruguay. Se destaca por su persistencia y alta producción de forraje entre los meses de septiembre y mayo. Es una especie ideal para la recomposición de tapices naturales. Se adapta bien a una gran variabilidad de suelos, drenajes y manejos, siendo la especie gramínea más persistente entre las especies sembradas.",
      img: "./imagenes-modal/Paspalum.jpg"
    },
    {
      nombre: "ALFALFA",
      desc: "Alfalfa es una especie leguminosa perenne. Es una especie que concentra su producción en las estaciones de primavera- verano-otoño. Es considerada la “reina de las forrajeras” debido a la alta productividad de forraje de calidad que puede lograr bajo las condiciones de suelo y agua adecuadas. Alfalfa se comporta bien en suelos con buen drenaje y pH mayor a 5,3. Alfalfa pura puede causar meteorismo, por lo que requiere un manejo específico para controlar el mismo.",
      img: "./imagenes-modal/Alfalfa.jpg"
    },
    {
      nombre: "LOTUS CORNICULATUS",
      desc: "Lotus corniculatus es una especie leguminosa perenne. Es una especie que concentra su producción en las estaciones de primavera-verano-otoño. Produce forraje de alta calidad y se destaca por sobre la alfalfa por tener una mayor tolerancia a suelos de pH más bajo y no producir problemas de meteorismo.",
      img: "./imagenes-modal/LotusC.jpg"
    },
    {
      nombre: "LOTUS ULIGINOSUS",
      desc: "Lotus uliginosus es una especie leguminosa perenne. Concentra su producción en las estaciones de primavera-verano-otoño. Produce forraje de alta calidad y, al igual que lotus corniculatus, se destaca por sobre la alfalfa por tener una mayor tolerancia a suelo de pH más bajo y no producir problemas de meteorismo. Lotus uliaginosus tiene un mejor comportamiento en suelos con drenaje imperfecto que Lotus corniculatus.",
      img: "./imagenes-modal/LotusU.jpg"
    },
    {
      nombre: "RAIGRAS PERENNE",
      desc: "Raigrás perenne es una gramínea que puede persistir entre 2 y 3 años para las condiciones del Uruguay. Se caracteriza por la producción de forraje en las estaciones de otoño-invierno-primavera. Se destaca por sobre otras gramíneas perennes y bianuales por la calidad del forraje producido.",
      img: "./imagenes-modal/RaigrasPerenne.jpg"
    },
    {
      nombre: "ACHICORIA",
      desc: "Achicoria es una especie de la familia de las asteráceas (compuestas) con un ciclo bianual. Es una especie que se caracteriza por producir gran cantidad de forraje en las estaciones de primavera-verano-otoño. Achicoria produce bien en gran variabilidad de suelos.",
      img: "./imagenes-modal/Achicoria.jpg"
    },
    {
      nombre: "CEBADILLA",
      desc: "Cebadilla o Bromus catharticus es una especie gramínea bianual. Se caracteriza por la producción de forraje en las estaciones de otoño-invierno-primavera. Se destaca por sobre el raigrás perenne por la cantidad de forraje producido, así como por una mayor tolerancia al déficit hídrico en verano.",
      img: "./imagenes-modal/Cebadilla.jpg"
    },
    {
      nombre: "GRAMINEA C4 perenne",
      desc: "Paspalum es una gramínea perenne estival nativa del Uruguay. Se destaca por su persistencia y alta producción de forraje entre los meses de septiembre y mayo. Es una especie ideal para la recomposición de tapices naturales. Se adapta bien a una gran variabilidad de suelos, drenajes y manejos, siendo la especie gramínea más persistente entre las especies sembradas.",
      img: "./imagenes-modal/Paspalum.jpg"
    },
    {
      nombre: "HOLCUS",
      desc: "Holcus es una gramínea bianual de ciclo invernal con alta producción en invierno y primavera. Es una especie ideal para ser usada en los suelos de menor potencial, ya que se adapta a una amplia gama de condiciones, incluyendo suelos livianos, pesados, con problemas de drenaje, zonas húmedas y suelos ácidos.",
      img: "./imagenes-modal/Holcus.jpg"
    },
    {
      nombre: "TREBOL ROJO",
      desc: "Trébol rojo y Achicoria son dos especies bianuales estivales que complementan muy bien su producción de forraje. Dentro de las leguminosas tradicionalmente cultivadas en Uruguay, el trébol rojo se destaca por ser la de mayor producción de forraje en otoño-invierno. Esta especie se adapta a suelos ligeramente ácidos y pesados donde la alfalfa no se desarrolla bien. Sin embargo, es muy sensible a la falta de agua. La inclusión de achicoria en la mezcla con trébol rojo reduce el riesgo de meteorismo.",
      img: "./imagenes-modal/TrebolRojoAchicoria.jpg"
    }
  ];

  const arrayForMalesas = [
    {
      nombre: "FESTUCA",
      gramilla: {
        bajo: "",
        medio: "No sembrar pasturas largas. Sembrar pasturas cortas o verdeos.",
        alto: "No se recomienda la siembra de pasturas. Sembrar secuencia de verdeos para luego de controlada la gramilla, volver a la fase pasturas."
      },
      raigras: {
        bajo: "",
        alto: "Las raigrás puede afectar la implantación de gramíneas perennes. Opciones de manejo: realizar siembra tardía con control previo de nacimientos otoñales, realizar siembra de verdeos o pasturas cortas para disminuir banco de semillas y luego ir a pasturas perennes de larga duración con base gramínea."
      },
      pgv: {
        bajo: "",
        alto: "Las gramineas inveranles poa, gaudiñnia y vulpia puede afectar la implantación de gramíneas perennes. Opciones de manejo: realizar siembra tardía con control previo de nacimientos otoñales, realizar siembra de verdeos o pasturas cortas para disminuir banco de semillas y luego ir a pasturas perennes de larga duración con base gramínea."
      },
      margarita: {
        bajo: "",
        alto: ""
      }
    },
    {
      nombre: "DACTYLIS",
      gramilla: {
        bajo: "",
        medio: "No sembrar pasturas largas. Sembrar pasturas cortas o verdeos.",
        alto: "No se recomienda la siembra de pasturas. Sembrar secuencia de verdeos para luego de controlada la gramilla, volver a la fase pasturas."
      },
      raigras: {
        bajo: "",
        alto: "Las raigrás puede afectar la implantación de gramíneas perennes. Opciones de manejo: realizar siembra tardía con control previo de nacimientos otoñales, realizar siembra de verdeos o pasturas cortas para disminuir banco de semillas y luego ir a pasturas perennes de larga duración con base gramínea."
      },
      pgv: {
        bajo: "",
        alto: "Las gramineas inveranles poa, gaudiñnia y vulpia puede afectar la implantación de gramíneas perennes. Opciones de manejo: realizar siembra tardía con control previo de nacimientos otoñales, realizar siembra de verdeos o pasturas cortas para disminuir banco de semillas y luego ir a pasturas perennes de larga duración con base gramínea."
      },
      margarita: {
        bajo: "",
        alto: ""
      }
    },
    {
      nombre: "ALFALFA",
      gramilla: {
        bajo: "",
        medio: "La gramilla puede afectar la implantación y crecimiento de la pastura. No sembrar con una graminea como especie acompañante y considerar la realización de controles con germinicidas selectivos en primavera/verano.",
        alto: "No se recomienda la siembra de pasturas. Sembrar secuencia de verdeos para luego de controlada la gramilla, volver a la fase pasturas."
      },
      raigras: {
        bajo: "",
        alto: ""
      },
      pgv: {
        bajo: "",
        alto: ""
      },
      margarita: {
        bajo: "",
        alto: "No hay alternativas de control de margarita de piria con esta especie base. Considerar la posibilidad de realizar una siembra en primavera."
      }
    },
    {
      nombre: "LOTUS CORNICULATUS",
      gramilla: {
        bajo: "",
        medio: "No sembrar pasturas largas. Sembrar pasturas cortas o verdeos.",
        alto: "No se recomienda la siembra de pasturas. Sembrar secuencia de verdeos para luego de controlada la gramilla, volver a la fase pasturas."
      },
      raigras: {
        bajo: "",
        alto: ""
      },
      pgv: {
        bajo: "",
        alto: ""
      },
      margarita: {
        bajo: "",
        alto: "No hay alternativas de control de margarita de piria con esta especie base. Se recomienda realizar praderas gramíneas puras o verdeos."
      }
    },
    {
      nombre: "LOTUS ULIGINOSUS",
      gramilla: {
        bajo: "",
        medio: "No sembrar pasturas largas. Sembrar pasturas cortas o verdeos.",
        alto: "No se recomienda la siembra de pasturas. Sembrar secuencia de verdeos para luego de controlada la gramilla, volver a la fase pasturas."
      },
      raigras: {
        bajo: "",
        alto: ""
      },
      pgv: {
        bajo: "",
        alto: ""
      },
      margarita: {
        bajo: "",
        alto: "No hay alternativas de control de margarita de piria con esta especie base. Se recomienda realizar praderas gramíneas puras o verdeos."
      }
    },
    {
      nombre: "RAIGRAS PERENNE",
      gramilla: {
        bajo: "",
        medio: "La gramilla puede afectar la implantación y crecimiento de la pastura.",
        alto: "No se recomienda la siembra de pasturas. Sembrar secuencia de verdeos para luego de controlada la gramilla, volver a la fase pasturas."
      },
      raigras: {
        bajo: "",
        alto: ""
      },
      pgv: {
        bajo: "",
        alto: ""
      },
      margarita: {
        bajo: "",
        alto: "No hay alternativas de control de margarita de piria con esta especie base. Se recomienda realizar praderas gramíneas puras o verdeos."
      }
    },
    {
      nombre: "ACHICORIA",
      gramilla: {
        bajo: "",
        medio: "La gramilla puede afectar la implantación y crecimiento de la pastura. No sembrar con una graminea como especie acompañante y considerar la realización de controles con germinicidas selectivos en primavera/verano.",
        alto: "No se recomienda la siembra de pasturas. Sembrar secuencia de verdeos para luego de controlada la gramilla, volver a la fase pasturas."
      },
      raigras: {
        bajo: "",
        alto: ""
      },
      pgv: {
        bajo: "",
        alto: ""
      },
      margarita: {
        bajo: "",
        alto: "No hay alternativas de control de margarita de piria con esta especie base. Se recomienda realizar praderas gramíneas puras o verdeos."
      }
    },
    {
      nombre: "CEBADILLA",
      gramilla: {
        bajo: "",
        medio: "La gramilla puede afectar la implantación y crecimiento de la pastura.",
        alto: "No se recomienda la siembra de pasturas. Sembrar secuencia de verdeos para luego de controlada la gramilla, volver a la fase pasturas."
      },
      raigras: {
        bajo: "",
        alto: ""
      },
      pgv: {
        bajo: "",
        alto: ""
      },
      margarita: {
        bajo: "",
        alto: ""
      }
    },
    {
      nombre: "GRAMINEA C4 perenne",
      gramilla: {
        bajo: "",
        medio: "No sembrar pasturas largas. Sembrar pasturas cortas o verdeos.",
        alto: "No se recomienda la siembra de pasturas. Sembrar secuencia de verdeos para luego de controlada la gramilla, volver a la fase pasturas."
      },
      raigras: {
        bajo: "",
        alto: ""
      },
      pgv: {
        bajo: "",
        alto: ""
      },
      margarita: {
        bajo: "",
        alto: ""
      }
    },
    {
      nombre: "HOLCUS",
      gramilla: {
        bajo: "",
        medio: "",
        alto: "No se recomienda la siembra de pasturas. Sembrar secuencia de verdeos para luego de controlada la gramilla, volver a la fase pasturas."
      },
      raigras: {
        bajo: "",
        alto: ""
      },
      pgv: {
        bajo: "",
        alto: ""
      },
      margarita: {
        bajo: "",
        alto: ""
      }
    },
    {
      nombre: "TREBOL ROJO",
      gramilla: {
        bajo: "",
        medio: "La gramilla puede afectar la implantación y crecimiento de la pastura. No sembrar con una graminea como especie acompañante y considerar la realización de controles con germinicidas selectivos en primavera/verano.",
        alto: "No se recomienda la siembra de pasturas. Sembrar secuencia de verdeos para luego de controlada la gramilla, volver a la fase pasturas."
      },
      raigras: {
        bajo: "",
        alto: ""
      },
      pgv: {
        bajo: "",
        alto: ""
      },
      margarita: {
        bajo: "",
        alto: "No hay alternativas de control de margarita de piria con esta especie base. Se recomienda realizar praderas gramíneas puras o verdeos."
      }
    }
  ]

  const {formState, onInputChange, onResetForm} = useForm({});
  const [result, setResult] = useState([]);
  useEffect(() => {
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...formState })
      };
      
      fetch(process.env.REACT_APP_API_URL + "api/especie", requestOptions)
          .then(response => response.json())
          .then(function(data){
            if (firstList.length === 0) {
              firstList = data;
            }
            phValue = formState.ph;
            drenajeValue = formState.drenaje;
            
            return setResult(data);
          })
  }, [formState]);

  const resetForm = () => { 
    //document.getElementById("form").reset();
    //onResetForm();
    window.location.reload();
  }
  let messageError = "";
  if (result.toString().includes("SIN PRADERA CORTA") && result.length < 2) {
    messageError = "Debido a las condiciones de suelo y objetivos productivos especificados, NO se recomiendasembrar praderas cortas.";
  } else if (result.toString().includes("SIN PRADERA LARGA") && result.length < 2) {
    messageError = "Debido a las condiciones de suelo y objetivos productivos especificados, NO se recomienda sembrar praderas largas.";
  } else if (result.toString().includes("SIN PRADERA PARA SIEMPRE") && result.length < 2) {
    messageError = "Debido a las condiciones de suelo y objetivos productivos especificados, NO se recomienda sembrar praderas para siempre.";
  }
  
  function updateConeat(data){

    const radioButtonsDreanaje = document.getElementsByName('drenaje');

  // Loop through the radio buttons and set the checked property based on the value
  radioButtonsDreanaje.forEach(radioButton => {
    if (radioButton.value === data.drenaje) {
      radioButton.checked = true;
      const event = new Event('change');
      radioButton.dispatchEvent(event);
      onInputChange(event);
    }
  });

  const radioButtonsDeficit = document.getElementsByName('deficit');

  // Loop through the radio buttons and set the checked property based on the value
  radioButtonsDeficit.forEach(radioButton => {
    if (radioButton.value === data.profundidad) {
      radioButton.checked = true;
      const event = new Event('change');
      radioButton.dispatchEvent(event);
      onInputChange(event);
    }
  });

  const radioButtonsPH = document.getElementsByName('ph');

  // Loop through the radio buttons and set the checked property based on the value
  radioButtonsPH.forEach(radioButton => {
    if (radioButton.value === data.ph) {
      radioButton.checked = true;
      const event = new Event('change');
      radioButton.dispatchEvent(event);
      onInputChange(event);
    }
  });
  }
  const handleAcompananteClick = (event) => {
    // Construct the URL with the extracted values
    let especie = event.currentTarget.dataset.idEspecie;
    especie = event.currentTarget.dataset.idEspecie.includes("TREBOL") ? "TREBOLROJO" : especie;
    especie = event.currentTarget.dataset.idEspecie.includes("C4") ? "C4" : especie;
    setSelectedSpecies(especie);
    setTimeout(function() {
      const acompanantesSection = document.getElementById('acompanantes');
      if(acompanantesSection) {
        acompanantesSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 1000)
  };

  return(
    <>
      <div className="container py-3">
        <header className='text-center mb-5'>
          <a href='/'>
            <img className='m-3' src='logo.png'></img>
          </a>
          <img className='mb-4' src="Portada.jpeg"></img>
          <h2>PasturApp</h2>
          <p style={{fontSize: "20px"}}><strong>PasturApp</strong> es una herramienta que ayuda a definir que especies y mezclas forrajeras mejor se adaptan a los objetivos productivos del sistema y a las propiedades fisicoquímicas del suelo de cada potrero. Esta herramienta condensa tanto información generada por la investigación como conocimiento experto de referentes en el manejo de forrajeras.</p>
        </header>
        <main>
          <form id="form">
            <div className="row">
              <div className="col-sm-8">
                <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
                  <div className="col">
                    <div className="card mb-6 rounded-3 shadow-sm border-primary my-1">
                      <div className="card-header text-white bg-primary border-primary">
                        <h4 className="my-0 fw-normal">Duración <img className='ctooltip' alt='Duración' src='images/info-circle-fill.svg'></img>
                          <span className="ctooltiptext">¿Cuántos años espera que dure la pradera?</span>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className='input-radio'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="duracion" id="duracion1" value="PARA SIEMPRE" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="duracion1">
                              Pasturas Largas (+ de 5 años)
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="duracion" id="duracion2" value="PASTURAS LARGAS" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="duracion2">
                              Pasturas Largas (3 – 5 años)
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="duracion" id="duracion3" value="PASTURAS CORTAS" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="duracion3">
                              Pasturas Cortas
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card mb-6 rounded-3 shadow-sm border-primary my-1">
                      <div className="card-header text-white bg-primary border-primary">
                        <h4 className="my-0 fw-normal">Estacionalidad <img className='ctooltip' alt='Estacionalidad' src='images/info-circle-fill.svg'></img>
                          <span className="ctooltiptext">¿Cuándo prefiere sea el pico de producción de la mezcla forrajera?</span>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className='input-radio'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="estacionalidad" id="estacionalidad1" value="MAY-OCT" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="estacionalidad1">
                              Mayo - Octubre
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="estacionalidad" id="estacionalidad2" value="NOV-MAR" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="estacionalidad2">
                              Noviembre - Marzo
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
                  <div className="col">
                    <div className="card mb-6 rounded-3 shadow-sm border-primary my-1">
                      <div className="card-header text-white bg-primary border-primary">
                        <h4 className="my-0 fw-normal">Priorizar Cantidad, Calidad o Persistencia? <img className='ctooltip' alt='Priorizar CANTIDAD CALIDAD o PERSISTENCIA?' src='images/info-circle-fill.svg'></img>
                          <span className="ctooltiptext">Según los objetivos productivos ¿Qué es más importante: la
cantidad del forraje producido, la calidad del forraje producido, o la persistencia de la pastura?</span>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className='input-radio'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="calidad" id="calidad1" value="calidad" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="calidad1">
                              Calidad
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="calidad" id="calidad2" value="cantidad" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="calidad2">
                              Cantidad
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="calidad" id="calidad3" value="persistencia" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="calidad3">
                              Persistencia
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card mb-6 rounded-3 shadow-sm border-primary my-1">
                      <div className="card-header text-white bg-primary border-primary">
                        <h4 className="my-0 fw-normal">Meteorismo <img className='ctooltip' alt='Meteorismo' src='images/info-circle-fill.svg'></img>
                          <span className="ctooltiptext">¿Es el meteorismo un problema manejable a nivel del predio?</span>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className='input-radio'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="meteorismo" id="meteorismo1" value="manejable" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="meteorismo1">
                              Manejable
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="meteorismo" id="meteorismo2" value="no manejable" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="meteorismo2">
                              No Manejable
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                   <SelectConeat onUpdate={ updateConeat }/>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
                  <div className="col">
                    <div className="card mb-6 rounded-3 shadow-sm border-warning my-1">
                      <div className="card-header text-white bg-warning border-warning">
                        <h4 className="my-0 fw-normal">Drenaje <img className='ctooltip' alt='Drenaje' src='images/info-circle-fill.svg'></img>
                          <span className="ctooltiptext">¿Cómo es el drenaje del potrero donde se va a sembrar?</span>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className='input-radio'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="drenaje" id="drenaje1" value="bueno" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="drenaje1">
                              Bueno
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="drenaje" id="drenaje2" value="ok" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="drenaje2">
                              Moderado
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="drenaje" id="drenaje3" value="malo" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="drenaje3">
                              Malo
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card mb-6 rounded-3 shadow-sm border-warning my-1">
                      <div className="card-header text-white bg-warning border-warning">
                        <h4 className="my-0 fw-normal">Profunidad Del Suelo <img className="ctooltip" alt='Déficit Agua Estival' src='images/info-circle-fill.svg'></img>
                          <span className="ctooltiptext">¿Qué capacidad de almacenar agua y soportar déficit hídricos tiene el suelo? Suelos profundos son aquellos que tienen alta capacidad de retener agua en el perfil.</span>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className='input-radio'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="deficit" id="deficit1" value="< 450 mm" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="deficit1">Profundo</label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="deficit" id="deficit2" value="450 - 600 mm" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="deficit2">Medio</label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="deficit" id="deficit4" value="> 600 mm" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="deficit4">Superficial</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card mb-6 rounded-3 shadow-sm border-warning my-1">
                      <div className="card-header text-white bg-warning border-warning">
                        <h4 className="my-0 fw-normal">pH <img className='ctooltip' alt='PH' src='images/info-circle-fill.svg'></img>
                          <span className="ctooltiptext">¿Cuál es el pH del potrero donde se va a sembrar?</span>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className='input-radio'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="ph" id="ph1" value="> 5.3" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="ph1">
                              Mayor a 5.3
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="ph" id="ph2" value="< 5.3" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="ph2">
                              Menor a 5.3
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4>Malezas existentes al momento de la siembra</h4>
                <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
                  <div className="col">
                    <div className="card mb-6 rounded-3 shadow-sm border-danger my-1">
                      <div className="card-header text-white bg-danger border-danger">
                        <h4 className="my-0 fw-normal">Gramilla <img className='ctooltip' alt='gramilla' src='images/info-circle-fill.svg'></img>
                          <span className="ctooltiptext">Malezas existentes al momento de la siembra</span>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className='input-radio'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="gramilla" id="gramillapoco" value="gramillapoco" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="gramillapoco">
                              Poco (0 – 5%)
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="gramilla" id="gramillamedio" value="gramillamedio" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="gramillamedio">
                              Medio (5 – 15%)
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="gramilla" id="gramillamucho" value="gramillamucho" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="gramillamucho">
                              Mucho (+15%)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4>Historia del potrero – Banco de semillas</h4>
                <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
                  <div className="col">
                    <div className="card mb-6 rounded-3 shadow-sm border-danger my-1">
                      <div className="card-header text-white bg-danger border-danger">
                        <h4 className="my-0 fw-normal">Raigrás <img className='ctooltip' alt='raigras' src='images/info-circle-fill.svg'></img>
                          <span className="ctooltiptext">Banco de semillas según la historia del potrero.</span>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className='input-radio'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="raigras" id="raigraspoco" value="raigraspoco" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="raigraspoco">
                              Poco
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="raigras" id="raigrasmucho" value="raigrasmucho" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="raigrasmucho">
                              Mucho
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card mb-6 rounded-3 shadow-sm border-danger my-1">
                      <div className="card-header text-white bg-danger border-danger">
                        <h4 className="my-0 fw-normal">Poa - Gaudinia - Vulpia <img className='ctooltip' alt='pgv' src='images/info-circle-fill.svg'></img>
                          <span className="ctooltiptext">Banco de semillas</span>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className='input-radio'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="pgv" id="pgvpoco" value="pgvpoco" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="pgvpoco">
                              Poco
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="pgv" id="pgvmucho" value="pgvmucho" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="pgvmucho">
                              Mucho
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card mb-6 rounded-3 shadow-sm border-danger my-1">
                      <div className="card-header text-white bg-danger border-danger">
                        <h4 className="my-0 fw-normal">Margarita de piria <img className='ctooltip' alt='margarita' src='images/info-circle-fill.svg'></img>
                          <span className="ctooltiptext">Banco de semillas</span>
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className='input-radio'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="margarita" id="margaritapoco" value="margaritapoco" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="margaritapoco">
                              Poco
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="margarita" id="margaritamucho" value="margaritamucho" onChange={onInputChange} />
                            <label className="form-check-label" htmlFor="margaritamucho">
                              Mucho
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className='col-md-6'>
                    <button type="button" className="btn btn-primary" onClick={resetForm}>Limpiar Formulario</button>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='row text-left'>
                  <div className='col-md-12'>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col"><h3>Especie BASE recomendada</h3></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                          firstList.map((elem, index) => {
                              let disponible = false;
                              for (var i = 0; i < result.length; i++) {
                                if(result[i] === elem){
                                  disponible = true;
                                }
                              }
                              let info = "";
                              if (disponible) {
                                for (var i = 0; i < arrayForMalesas.length; i++) {
                                  if (elem === arrayForMalesas[i].nombre) {
                                    info = (
                                      <div style={{ display: 'inline-block' }}>
                                        { obtenerValorSeleccionado("gramilla") == "gramillamedio" && arrayForMalesas[i].gramilla.medio != "" && (<div className={"d-inline-block me-1"}><img className='ctooltip' alt='circulo' src='images/info-circle-orange.svg' /> <span className="ctooltiptext">{arrayForMalesas[i].gramilla.medio}</span></div>)}
                                        { obtenerValorSeleccionado("gramilla") == "gramillamucho" && arrayForMalesas[i].gramilla.alto != "" && (<div className={"d-inline-block me-1"}><img className='ctooltip' alt='circulo' src='images/info-circle-red.svg' /> <span className="ctooltiptext">{arrayForMalesas[i].gramilla.alto}</span></div> )}
                                        { obtenerValorSeleccionado("raigras") == "raigrasmucho" && arrayForMalesas[i].raigras.alto != "" && (<div className={"d-inline-block me-1"}><img className='ctooltip' alt='circulo' src='images/flower1.svg' /> <span className="ctooltiptext">{arrayForMalesas[i].raigras.alto}</span></div> )}
                                        { obtenerValorSeleccionado("pgv") == "pgvmucho" && arrayForMalesas[i].pgv.alto != "" && (<div className={"d-inline-block me-1"}><img className='ctooltip' alt='circulo' src='images/flower2.svg' /> <span className="ctooltiptext">{arrayForMalesas[i].pgv.alto}</span></div> )}
                                        { obtenerValorSeleccionado("margarita") == "margaritamucho" && arrayForMalesas[i].margarita.alto != "" && (<div className={"d-inline-block me-1"}><img className='ctooltip' alt='circulo' src='images/flower3.svg' /> <span className="ctooltiptext">{arrayForMalesas[i].margarita.alto}</span></div> )}
                                      </div>
                                    );
                                  }
                                }
                              }
                              if (elem != "SIN PRADERA CORTA" && elem != "SIN PRADERA LARGA" && elem != "SIN PRADERA PARA SIEMPRE") {  
                                return (
                                  <tr key={index}>
                                    <td className={"p-0"}>
                                      <span onClick={(e) => handleCtooltipClick(e, elem)} className={disponible ? 'fw-bold btn' : 'fw-lighter btn'}>{elem}</span>
                                      {info}
                                    </td>
                                  </tr>
                                )
                              }
                          })  
                      }
                      </tbody>
                    </table>
                    <h5 className='text-danger'>{messageError}</h5>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </main>
      </div>
      {
        arrayForModal.map((elem, index) => (       
              <div
                      key={index}
                      className={isModalOpen ? "modal fade show" : "modal fade"}
                      id={elem.nombre.replace(/\s+/g, '')}
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden={!isModalOpen}
                      ref={modalRef}
                    >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">{elem.nombre}</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <img src={elem.img} alt="" />
                      <br /><br />
                      <p>{elem.desc}</p>
                    </div>
                    <div className="modal-footer">
                      { (!elem.nombre.includes("ACHICORIA") || elem.nombre.includes("TREBOL") )  && (
                        <button data-id-especie={elem.nombre.replace(/\s+/g, '')} type="button" className="btn btn-primary" onClick={handleAcompananteClick} data-bs-dismiss="modal">Especie Acompañante</button>
                      )}
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                  </div>
                </div>
              </div>
            )
        )
      }
      <div id="acompanantes" className="container py-3">
      {selectedSpecies === 'DACTYLIS' && <DACTYLIS ph={phValue} drenaje={drenajeValue} />}
      {selectedSpecies === 'FESTUCA' && <FESTUCA />}
      {selectedSpecies === 'LOTUSCORNICULATUS' && <LOTUSCORNICULATUS />}
      {selectedSpecies === 'LOTUSULIGINOSUS' && <LOTUSULIGINOSUS />}
      {selectedSpecies === 'ALFALFA' && <ALFALFA />}
      {selectedSpecies === 'C4' && <C4 />}
      {selectedSpecies === 'TREBOLROJO' && <TREBOLROJO />}
      {selectedSpecies === 'RAIGRASPERENNE' && <RAIGRASPERENNE />}
      {selectedSpecies === 'CEBADILLA' && <CEBADILLA />}
      {selectedSpecies === 'HOLCUS' && <HOLCUS />}
      </div>
    </>
  )
}

export default App;