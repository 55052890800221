import React, { useState, useEffect } from 'react';

const SelectConeat = ({ onUpdate }) => {
  const [coneatOptions, setConeatOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "api/coneat", {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
       
        setConeatOptions(data.ids);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
   
    
    fetchData();

    return () => {
      // Cleanup function (if needed)
    };
  }, []); // Empty dependency array, so it runs only once when the component mounts
  const handleSelectionChange = async (selectedValue) => {
    setSelectedOption(selectedValue);

    // Make a POST request to api/coneat/{id}
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `api/coneat/${selectedValue}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        // You can include a request body if needed
        // body: JSON.stringify({ /* your data */ })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      
       onUpdate(responseData);
      // Handle the response as needed
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  };
  return (
<div className="row form-row align-items-center mb-3 mt-4">
  <div className="col-3">
    <label htmlFor="coneatSelect" className="col-form-label"><h4 className="my-0 fw-normal">Índice CONEAT:</h4></label>
  </div>
  <div className="col-7 col-sm-8">
    <select
      id="coneatSelect"
      className="form-control"
      value={selectedOption}
      onChange={(e) => handleSelectionChange(e.target.value)}
    >
      <option value="">Seleccionar...</option>
      {coneatOptions.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
    </div>
    <div className="col-2 col-sm-1">
    <img className='ctooltip' alt='Coneat' src='images/info-circle-black.svg'></img>
    <span className="ctooltiptext">Si lo desea, puede seleccionar su índice CONEAT para completar automáticamente
    los valores de drenaje, profundidad de suelo y pH</span>
    </div>

</div>

  );
};

export default SelectConeat;