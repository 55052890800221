import React, { useEffect, useState } from 'react';
import '../App.css';
import { useForm } from '../../hooks/useForm.js';
let firstList = [];

function HOLCUS() {
    const { formState, onInputChange } = useForm();
    const [result, setResult] = useState([]);

    useEffect(() => {
        const body = firstList.length === 0 ? [] : JSON.stringify({ ...formState });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body
        };
        fetch(process.env.REACT_APP_API_URL + "api/secundaria/HOLCUS", requestOptions)
            .then(response => response.json())
            .then(function (data) {
                if (firstList.length === 0) {
                    firstList = data;
                }
                return setResult(data);
            })
        return () => {
        }
    }, [formState]);

    const resetForm = () => {
        window.location.reload();
    }

    return (
        <>
            <header className='text-center mb-5'>
                <p style={{ fontSize: "20px" }}><strong>ESPECIE BASE HOLCUS</strong></p>
            </header>
            <main>
                <form id="form">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
                                <div className="col">
                                    <div className="card mb-6 rounded-3 shadow-sm border-success my-1">
                                        <div className="card-header text-white bg-success border-success">
                                            <h4 className="my-0 fw-normal">Simplicidad de manejo <img className='ctooltip' alt='Simplicidad' src='images/info-circle-fill.svg'></img>
                                                <span className="ctooltiptext">¿Preferiría un manejo simple y óptimo para Cebadilla con alta fertilización con N, una mezcla menos simple de manejar pero con mayor productividad y calidad estival, o una mezcla más compleja de manejar pero con mayor productividad y calidad estival y que además necesite menos fertilización con N?</span>
                                            </h4>
                                        </div>
                                        <div className="card-body">
                                            <div className='input-radio'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="simplicidad8" id="simplicidad81" value="Simplicidad y fert N" onChange={onInputChange} />
                                                    <label className="form-check-label" htmlFor="simplicidad81">Simplicidad de manejo</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="simplicidad8" id="simplicidad82" value="+PPNA + calidad y fert N" onChange={onInputChange} />
                                                    <label className="form-check-label" htmlFor="simplicidad82">Manejo menos simple pero mayor productividad y calidad</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="simplicidad8" id="simplicidad83" value="+PPNA +calidad y FBN" onChange={onInputChange} />
                                                    <label className="form-check-label" htmlFor="simplicidad83">Manejo menos simple pero mayor productividad y calidad, y menor uso de fertilización con N</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-md-6'>
                                    <button type="button" className="btn btn-primary" onClick={resetForm}>Limpiar Formulario</button>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='row text-left'>
                                <div className='col-md-12'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"><h3>Especies acompañantes recomendadas</h3></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                firstList.map((elem, index) => {
                                                    let disponible = false;
                                                    for (var i = 0; i < result.length; i++) {
                                                        if (result[i] === elem) {
                                                            disponible = true;
                                                        }
                                                    }
                                                    return (
                                                        <tr key={index}>
                                                            <td className={disponible ? 'fw-bold' : 'fw-lighter'}>{elem} {disponible}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </main>
        </>
    )
}

export default HOLCUS;